<template>
  <div class="row vh-100 m-0">
    <div class="d-flex align-items-center justify-content-center">
      <div class="d-flex flex-column">
        <h4 class="text-secondary">O que você deseja fazer?</h4>
        <router-link
          to="schedules/new"
          class="btn btn-secondary btn-lg mb-3"
          title="Criar Novo Agendamento"
        >
          <i class="ph ph-calendar-plus me-2"></i>
          Novo Agendamento
        </router-link>
        <router-link
          class="btn btn-secondary btn-lg mb-3"
          to="schedules/list"
          title="Visualizar Agendamentos"
        >
          <i class="ph ph-calendar me-2"></i>
          Agendamentos
        </router-link>
        <router-link
          class="btn btn-secondary btn-lg"
          to="collaborators"
          title="Visualizar Funcionários"
        >
          <i class="ph ph-users me-2"></i>
          Funcionários
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
