<template>
  <div class="row vh-100 m-0 mt-lg-5 me-lg-5">
    <div
      class="d-flex align-items-center justify-content-center justify-content-lg-end"
    >
      <form class="login-form" @submit.prevent="auth">
        <h6 class="fw-light text-center text-white mb-3">
          Voce acessará como gestor
        </h6>
        <div class="card mb-0">
          <div class="card-body">
            <div class="text-center mb-3">
              <div
                class="d-inline-flex align-items-center justify-content-center my-2 d-lg-none"
              >
                <img src="@/assets/img/logos/icone-02.svg" class="h-48px" />
              </div>
              <h4 class="text-secondary mb-0">Conecte ao sistema</h4>
            </div>
            <div class="mb-3">
              <label class="form-label">CNPJ</label>
              <div class="form-control-feedback form-control-feedback-start">
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.cnpj.$errors.length }"
                  placeholder="00.000.000/0000-00"
                  v-model="v$.cnpj.$model"
                  v-maska="'##.###.###/####-##'"
                />
                <div class="form-control-feedback-icon">
                  <i class="ph-house-line text-muted"></i>
                </div>
                <span v-if="v$.cnpj.required.$invalid" class="invalid-feedback">
                  O campo é obrigatório
                </span>
                <span
                  v-if="v$.cnpj.minLength.$invalid"
                  class="invalid-feedback"
                >
                  Formato de CNPJ inválido <br />
                  Ex: 00.000.000/0000-00
                </span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Código da Empresa</label>
              <div class="form-control-feedback form-control-feedback-start">
                <input
                  type="code"
                  class="form-control"
                  :class="{ 'is-invalid': v$.code.$errors.length }"
                  placeholder="•••••••••••"
                  v-model="v$.code.$model"
                />
                <div class="form-control-feedback-icon">
                  <i class="ph-lock text-muted"></i>
                </div>
                <span v-if="v$.code.required.$invalid" class="invalid-feedback">
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="mb-3">
              <button
                type="submit"
                class="btn btn-secondary w-100"
                title="Login"
                :disabled="v$.$invalid || disableButton"
              >
                <span v-if="disableButton">
                  <i class="ph-spinner spinner" />
                </span>
                <span v-else>Login</span>
              </button>
            </div>
            <div class="text-center" title="Alterar perfil de acesso">
              <router-link to="/">Alterar perfil de acesso</router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { maska } from 'maska'
import PATHS from '@/router/routesMap'

export default {
  name: 'ManagerAuth',

  setup() {
    return { v$: useVuelidate() }
  },

  directives: { maska },

  data() {
    return {
      backgroundImg: 'background-14.jpg',
      cnpj: '',
      code: '',
      disableButton: false,
    }
  },

  methods: {
    async auth() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          await this.$store.dispatch('authManager', {
            cnpj: this.cnpj,
            code: this.code,
          })
          this.disableButton = false
          this.$router.push(PATHS.MANAGER_HOME)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      cnpj: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18),
      },
      code: {
        required,
      },
    }
  },

  created() {
    this.$store.dispatch('setBackground', this.backgroundImg)
  },
}
</script>
